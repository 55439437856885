@charset "UTF-8";
/*
Name: High contrast (B/W)
Version: 1.01
Author: Opera Software ASA
Description: High contrast, black on white.

Copyright © 2003 Opera Software ASA.
*/

* {
	background: White !important;
	color: Black !important;
}






button, input[type="file"], input[type="submit"], input[type="button"], input[type="reset"] {
	background: Silver !important;
	color: Black !important;
}

a[href] {text-decoration: underline !important;}

a[href]:hover {
	background-color: Black !important;
	color: White !important;
}

a[href] img {border: thin solid Black !important;}

@media screen, projection, handheld, tv {
input, textarea, select {
	background: #FFFFCC !important; /*Light yellow*/
	color: Black !important;
}
}

